import { Target, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class AdminSidebarController extends Controller {
  @Target sidebarTarget: HTMLDivElement;

  toggleSidebar() {
    this.sidebarTarget.dataset.ui = this.sidebarTarget.dataset.ui?.includes("open") ? "" : "open";
  }
}
