import { TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller<HTMLDivElement> {

  connect() {
    this.focusOnFieldIfError()
  }

  private focusOnFieldIfError() {
    const errorMessages = document.getElementsByClassName('field-error-message')

    const firstErrorMessage = errorMessages[0];
    if (firstErrorMessage) {
      firstErrorMessage.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
}
