import { TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class AdminSidebarBrandSelectorController extends Controller<HTMLSelectElement> {
	connect() {
		this.element.addEventListener("change", (event) => {
			// @ts-ignore
			window.location.href = event.target.value;
		});
	}
}
