import { TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

import ModalController from "../../components/modal/controller";

@TypedController
export default class extends Controller<HTMLButtonElement> {
  static outlets = ["modal"];

  declare readonly modalOutlet: ModalController;

  open() {
    this.modalOutlet.openModal();
  }

  close() {
    this.modalOutlet.closeModal();
  }
}
